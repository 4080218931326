/* eslint-disable react/no-danger */
import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import QuoteBlock from "src/components/QuoteBlock"
import InsightCarousel from "src/components/insights/Carousel"
import Content from "src/components/Content"

import "./serviceTemplate.scss"

const ServiceTemplate = ({data: {page: {frontmatter: {
  meta, hero, summary, resources, process
}}, quote, footerCards}}) => {
  
  return (
    <Layout meta={meta}>
      <div className="process-template">
        <PageHero {...hero} />
        {summary && (
          <section className="process-summary">
            <div className="container">
              <div className="row">
                {summary.map(item => (
                  <div
                    // data-sal="slide-up"
                    // data-sal-duration="1000"
                    // data-sal-easing="cubic-bezier(0.5, 0, 0, 1)"
                    key={item.title}
                    className="summary-item col-md offset-xs-2"
                  >
                    <img src={item.image} alt={item.title} />
                    <h3>{item.title}</h3>
                    <ul>
                      {item.points.map(point => (
                        <li key={point}>{point}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
        
        {quote && (
          <section className="process-quote">
            <QuoteBlock {...quote.frontmatter} />
          </section>
        )}

        {process && (
          <section className="process-allsteps">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-4">
                  <h2 className="h1">Our Process</h2>
                </div>
              </div>
              {process.map(step => (
                <div key={step.title} className="step row">
                  <div className="col-md-2 offset-md-1">
                    <img
                      // data-sal="slide-up"
                      // data-sal-duration="1000"
                      // data-sal-easing="cubic-bezier(0.5, 0, 0, 1)"
                      src={step.image}
                      alt={step.title}
                    />
                  </div>
                  <div className="col-md-7 offset-md-1">
                    <h3>{step.title}</h3>
                    <Content html={step.description} />
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
        
        {resources && (
          <section className="process-self-guided">
            <div className="container">
              <h2 className="h1">Self-Guided Resources</h2>
              {resources.map(resource => (
                <div key={resource.title} className="resource row">
                  <div className="col-md-5 offset-md-2">
                    <img src={resource.logo} alt={resource.logoAlt} />
                    <Content html={resource.description} />
                  </div>
                  <div className="col-md-2 offset-md-2 d-flex align-items-center justify-content-center">
                    <div className="image">
                      <a
                        href={resource.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={resource.image} alt={resource.imageAlt} />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}

        <section className="process-cards">
          <div className="container">
            <InsightCarousel cards={footerCards.cards} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ServiceTemplate

export const pageQuery = graphql`
  query($path: String!, $quote: String!, $footerCards: [String!]) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      ...HeroFragment
      frontmatter {
        summary {
          image
          title
          points
        }
        process {
          image
          title
          description
        }
        resources {
          title
          logo
          logoAlt
          image
          imageAlt
          description
          link
        }
      }
    }
    ...quoteQuery
    ...footerCardsQuery
  }
`
